.mt-deals {
  /* margin-top: 50px; */
}
.card-d2 {
}
.card-d1 {
}
.card-img {
  width: 260px;
  transition: all 0.5s;
  overflow: hidden;
}
.card-d2:hover .card-img {
  transform: scale(1.1);
}
.card-d2 {
  overflow: hidden;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  transition: all 0.5s;


}
.card-d1 {
  display: flex;
  gap: 10px;
  padding: 50px 0px;
  justify-content: center;
  flex-wrap: wrap;
}
.card-ab {
  position: absolute;
  z-index: 10;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  align-items: center;
  justify-content: flex-end;
}

.offer-h1 {
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}
.btn-buy-card {
  color: white;
  padding: 7px 11px;
  font-size: 11px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.418);
}
.btn-div-1 {
  display: flex;
  justify-content: center;

  /* visibility: hidden; */
  
}

/* 
.card-d2:hover .btn-div-1 {
  
  transition: all 0.5s;
  visibility:visible !important;
} */



.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInUp {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes fadeInUp {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  } 

  @media only screen and (max-width: 860px) {

    .card-d1 {

    }
    .card-img {
      width: 187px;
      transition: all 0.5s;
      overflow: hidden;
  }
  }

  
  @media only screen and (max-width: 430px) {
    .card-img {
      width: 143px !important;
      transition: all 0.5s;
      overflow: hidden;
  }
  .offer-h1 {
   
    font-size: 14px;
}
.btn-buy-card {
  color: white;
  padding: 6px 11px;
  font-size: 11px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.418);
}
.btn-div-1{
  display: none;
}
  }
  @media only screen and (max-width: 330px) {
    .card-img {
      width: 120px !important;
    }

  }

/* .btn-div-1{
  display: none;
}
  .card-d2:hover .btn-div-1{
  display: unset;


  } */