.cate-div {
}
.cate-div1 {
  display: flex;
  justify-content: center;
  padding: 50px 0px 200px 0px !important;
}
.cate-div2 {
  width: 90% !important;
}
.cate-heding {
  text-align: center;
  font-size: 25px;
}
.card-prod-3 {
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 15px;
}
.card-h-img3 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.card-img-1-p3 {
  width: 270px;
  height: 332px;
  object-fit: cover;
}
.card-prod-3:hover .p-a-card-f3 {
  visibility: visible;
}
.p-a-card-f3 {
  position: absolute;
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
  padding: 10px;
  background: black;
}
.p-a-card-f3 {
  visibility: hidden;
}
.q-v-h3 {
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
}
.cont-cat-card1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 90px 0px;
  gap: 5px;
}
@media only screen and (max-width: 800px) {
  .card-prod-3 {
    width: 240px;
  }
  .card-img-1-p3 {
    width: 240px;
  }
  .cate-div1 {
    display: flex;
    justify-content: center;
    padding: 20px 0px 100px 0px;
  }
}
@media only screen and (max-width: 670px) {
  .card-prod-3 {
    width: 170px;
  }
  .card-img-1-p3 {
    width: 170px;
    height: 158px;
  }
  .cont-cat-card1 {
    padding: 30px 0px;
    gap: 5px;
  }
}
@media only screen and (max-width: 490px) {
  .card-prod-3 {
    width: 130px;
  }
  .card-img-1-p3 {
    width: 130px;
  }
  .cate-div2 {
    width: 95%;
  }
  .cont-cat-card1 {
    display: flex;

    justify-content: center;

    gap: 5px;
  }
}

@media only screen and (max-width: 350px) {
  .cont-cat-card1 {
    display: flex;

    justify-content: center;

    gap: 5px;
  }
}

@media only screen and (max-width: 300px) {
  .cont-cat-card1 {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
  }
  .card-flex-3 {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.pro-name-h3 {
  font-size: 18px;
}

.card-flex-3 {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.cont-cat-card-c {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 50px 0px;
}
.card-head-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 270px;
}
.card-img-c {
  width: 270px;
  height: 332px;

  object-fit: cover;
  border-radius: 10px;
}
.card-con-c {
    text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 1012px) {
    .cont-cat-card-c {
      
        gap: 10px;
     
    
    }

}



@media only screen and (max-width: 740px) {
  .card-img-c {
    width: 189px;
    height: 197px;
  }

  .card-head-c {
    width: 189px;
  }
  .card-flex-3 {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 500px) {
  .card-head-c {
    width: 147px;
  }
  .card-img-c {
    width: 147px;
    /* height: 169px; */
  }
  .cont-cat-card-c {
    display: flex;
  }
  .pro-name-h3 {
    font-size: 15px !important;
  }
  .pro-name-h4 {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 400px) {
  .card-head-c {
    width: 130px;
  }
  .card-img-c {
    width: 130px;
    /* height: 169px; */
  }
  .cont-cat-card-c {
    display: flex;
  }
  .pro-name-h3 {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 370px) {
    .cont-cat-card-c {
        display: flex;
        gap: 5px;
        justify-content: center;
    }
}

@media only screen and (max-width: 310px) {
  .cont-cat-card-c {
    display: flex;
    gap: 5px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
}





.pro-name-h4 {
  font-size: 13px;
}
