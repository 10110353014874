.new-div1 {
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}
.new-div2 {
  width: 90%;
}
.new-h1 {
  font-size: 25px;
}
.border-line {
  border: 1px solid;
}
.new-flex-1 {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.carousel-div1 {
  display: flex;
  align-items: center;
}
.new-div5 {
  display: flex;
  justify-content: center;
}
.slider-img-1 {
  width: 250px;
  border-radius: 15px;
  height: 330px;
  object-fit: cover;
}
.new-c-top {
  /* margin-top: 50px; */
  padding: 50px 0px;
}
.slide-heading {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.new-div6 {
  display: flex;
  flex-direction: column;
  gap: 14px;
}


.new-h2{
  font-size: 12px;
    font-weight: 700;
}
.slider-img-carousel{
  width: 100%;
  object-fit: cover;
  height: 70vh;
}

@media only screen and (max-width: 670px) {
  .slider-img-1 {
    width: 180px;
    border-radius: 15px;
  height: 250px;

}

}


@media only screen and (max-width: 320px) {
  .slider-img-1 {
    width: 150px;
    border-radius: 15px;
}
}

.custom-next>svg,.custom-prev>svg{
  color: gray;
  font-size: 30px;
      /* border: 1px solid; */
      border-radius: 50%;
      background: white;
      /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
