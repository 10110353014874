.div-2-cart {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.heading-bag > h1 {
  font-size: 22px;
  padding: 25px 0px;
}
.heading-pay > h1 {
  font-size: 22px;
  font-size: 22px;
  padding: 25px 0px;
}
.product-img-cart > img {
  width: 160px;
}
.product-name-cart {
  font-size: 18px;
  font-weight: 500;
}

.p-price-cart {
  font-size: 18px;
  font-weight: 500;
}
.d-f-cart2 {
  display: flex;
  justify-content: space-between;
}
.cart-m-2 {
  width: 90%;
}
.cart-m-1 {
  width: 100%;
  display: flex;
  padding: 50px 0px 134px 0px;
  justify-content: center;
}
.left-h-cart {
  /* width: 50%; */
}
.right-h-cart {
  /* width: 20%; */
}
.lh-m1 {
}
.div-8-cart {
  display: flex;
  gap: 15px;
}
.d-f-cart-f {
  display: flex;
  gap: 15px;
}
.color-cart-p {
  font-size: 15px;
  font-weight: 500;
}

.d-f-cart-3 {
  display: flex;
  gap: 50px;
  align-items: center;
}
.d-f-cart-4 {
  display: flex;
  gap: 10px;
  align-items: center;
}
.d-f-cart-4 > svg {
  cursor: pointer;
}

.cart-gap-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main-d-p-c {
  padding-bottom: 26px;
  border-bottom: 0.5px solid #80808085;

  display: flex;
  flex-direction: column;
  gap: 25px;
}
.d-f-car-5 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.d-f-car-5 > h1 {
  font-size: 15px;
  font-weight: 500;
}
.head-pay-centent {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.border-b-c {
  border-bottom: 0.5px solid #80808085;
  padding: 12px 0px;
}
.check-out-btnn-cart{

  
  border: 1px solid;
  padding: 10px 127px;
  border-radius: 5px;
  background: black;
  color: white;
}
.check-out-btnn-cart:active{
transform: scale(0.8);

}
@media only screen and (max-width:1234px) {
  .div-2-cart {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    flex-direction: column;
    
}
}
@media only screen and (max-width:570px) {
  .d-f-cart-f {
    /* display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column; */
}
.product-img-cart > img {
  width: 75px;
}
.d-f-cart-3 {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.para-details-cart{
  font-size: 10px !important;
  width: unset !important;
}
.check-out-btnn-cart {
  
  padding: 10px 24px;

}
.cart-m-1 {
  width: 100%;
  display: flex;
  padding: 15px 0px 134px 0px;
  justify-content: center;
}
.empty-cart-div{
  padding:  50px 0px 200px 0px !important;
}
}



.para-details-cart{

  font-size: 11px;
  width: 300px;
}

.chec-out-btn-div{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
    
}



.cart-head-head{
  text-align: center;
  text-transform: capitalize;
}
.empty-cart-div{
  padding: 0px 0px 250px 0px;
  display: flex;
  align-items: center;
  gap: 60px;
  flex-direction: column;
}
.btn-empty-cart{

  border: 1px solid;
  padding: 10px 30px;
  background: black;
  border-radius: 5px;
  color: white;
}

@media only screen and (max-width:350px) {

  .product-name-cart {
    font-size: 11px;
    font-weight: 500;
}
.cart-gap-1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.para-details-cart {
  font-size: 9px !important;
  width: unset !important;
}
.d-f-cart-f {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

}