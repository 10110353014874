.bg-header{
    background-image: url('../../assets//images/bg-home.jpg');
    padding: 100px;
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


    /* background-attachment: fixed; */
 
}
.ban{

}
.sport{

}
.swiper-button-next,
.swiper-button-prev {
  color: white;
}

.hero-slider-area{
    display: flex;
    align-items: center;
}
.custom-prev1{
  position: absolute;
  z-index: 10;

}
.custom-next1{
  /* position: absolute;
  z-index: 10; */
}
.swiper-button-prev:after, .swiper-button-next:after{
  color: white;
  font-size: 20px !important;
}

@media only screen and (max-width: 800px) {
  .slider-img-carousel {
    width: 100%;
    object-fit: cover;
    height: 30vh !important;
}
}
.swiper-button-prev:after, .swiper-button-next:after {
}


