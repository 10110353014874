.scroll-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;


    z-index: 50;
  }

  .svg-scroll-top{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: black;
    background:white;
    padding: 3px;
    font-size: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
.svg-scroll-top:hover{
  /* border: 1px solid #12c2e9;
  transition:  0.8s; */


}
  
  .scroll-to-top.show {
    display: block;
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInRight {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInRight {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 