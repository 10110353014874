*{
  margin: 0;
  padding: 0;
  
  font-family: 'Montserrat', sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

ul li {
  list-style: none;
}
a{
  text-decoration: none;
  color: black;
}
button {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: 0!important;
}
.new-h2{
  color: gray;
}



@media only screen and (max-width: 700px) {
.new-div1 {

  padding: 25px 0px !important;
}
.cards-div-di {

  padding: 25px 0px !important;

}
.card-d1 {
  padding: 25px 0px !important;

}
.new-c-top {
  padding: 25px 0px !important;

}
}

.card-img-c{
  cursor: pointer;
}


.loader {
  border: 16px solid #f3f3f3; /* Light gray */
  border-top: 16px solid gray; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader-container{
  background: rgba(0, 0, 0, 0.247);
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 50;
  width: 100%;
  position: fixed;
  justify-content: center;
}