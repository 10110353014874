
.div-acc-1{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0px;
}
.div-acc-2{
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.lh-a{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 40px;
    border-radius: 5px;
}
.rh-a{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 40px;
    border-radius: 5px;
    width: 240px;

}
.head-acc{
    
}
.head-ach1{
    font-size: 20px;
}
.show-add {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px;
}
.address-p{
    font-size: 13px;

}
.name-add{
    font-size: 12px;

}

.phone-no-ad {
    font-weight: 300;
    font-size: 13px;
}
.phone-no-ad>span{
    font-weight: 700;
    font-size: 13px;
}

.p-acc-det{

    font-size: 13px;
    padding: 10px 0px;
}
.defaultaddress{
    font-size: 12px;
}
.def-add{
    display: flex;
    align-items: center;
    justify-content: space-between;
}