.sign-div1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px 183px 0px;
}
.sign-div2 {
  width: 80%;
}
.input-log-in {
  padding: 10px 20px;

  outline: none;
  border: 0.2px solid #80808082;
  border-radius: 5px;

  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.div-head-login-from {
}
.login-filed {
}

.login-form-section {
  display: flex;
  justify-content: center;
}
.head-sign {
  font-size: 20px;

  text-align: center;

  padding: 20px 0px;
}
.forg-p {
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  color: black;
}
.forg-p:hover{

    color: gray;
  }
  .btn-sign-in{

    
    border: 1px solid;
    padding: 10px 10px;
    border-radius: 5px;
    background: black;
    color: white;
    width: 100%;
  }
  .form-log-d5{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 420px;
  }

@media only screen and (max-width:500px) {
  .form-log-d5{

    width: unset !important;
  }
  .sign-div1 {

    padding: 10px 0px 100px 0px;
}

}
