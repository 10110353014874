.logo-nav {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}
.ul-nav {
  display: flex;
  gap: 20px;
  cursor: pointer;
  color: white;
}
.fix-nav {
  position: fixed;
  z-index: 20;
  width: 100%;
}
.fix-nav-z0{
  position: fixed;
  z-index: 0;
  width: 100%;
}
.nav-div2 {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  justify-content: space-around;

}
.nav-div3{
  display: flex;
  padding: 25px 20px;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0.2px solid #8080804a;
}
.search-input-nav {
  border: none;
  background: white;
}
.search-input-nav,
.search-input-nav1:focus {
  outline: none;
}
.search-nav {
  display: flex;
  align-items: center;
  border-radius: 5px;
  /* border: 1px solid #80808063; */
  gap: 1px;
  padding: 9px 21px;
  background: white;
}
.navbar-right-h-icons > svg {
  cursor: pointer;
}
.navbar-right-h-icons {
  display: flex;
  gap: 10px;
}
.sticky-nav {
  background-color: white;
}

.ul-black-nav {
  display: flex;
  gap: 20px;
  cursor: pointer;
  color: black;
}
.logo-nav-black {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  cursor: pointer;
}
.search-nav-gray {
  display: flex;
  align-items: center;
  border-radius: 5px;
  /* border: 1px solid #80808063; */
  gap: 1px;
  padding: 9px 21px;
  background: #d3d3d342;
}
.search-input-nav {
  border: none;
  background: white;
}
.search-input-nav1 {
  border: none;
  background: #d3d3d300;
}
.navbar-right-h-icons > svg {
  color: white;
}
.navbar-right-h-icons-black > svg {
  color: black;
  cursor: pointer;
}
.navbar-right-h-icons-black {
  display: flex;
  gap: 10px;
}

.l1>a{
  color: white;
}

.l1:after {
  content: "";
  background: none repeat scroll 0 0 transparent;
  display: block;
  height: 1px;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.l1:hover:after {
  width: 100%;
  left: 0;
}
.li-black:after {
  content: "";
  background: none repeat scroll 0 0 transparent;
  display: block;
  height: 1px;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.li-black:hover:after {
  width: 100%;
  left: 0;
}

.fadeInDown-nav {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (max-width: 930px) {
  .ul-black-nav,
  .ul-nav {
    display: none;
  }
  .search-nav-gray,
  .search-nav {
    display: none;
  }
  .nav-div2 {
    justify-content: space-between;
  }
  .log-nav-icons{
    display: none;

  }
  .mb-icons-nav{
    display: unset !important;
  }
  .nav-div3{
    justify-content: space-between;
  }
}

.logo-nav2 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  cursor: pointer;
}
.nav-links {
    color: gray;

}
.nav-links-black {
  color: lightgrey;
}
.mb-icons-nav{
  display: none;
}
.mb-icons-nav1{
  gap: 10px;
  display: flex;
  align-items: center;
}
.head-mobile-menu{
  padding: 25px 20px;
}
.close-icon-nav{

  display: flex;
  justify-content: flex-end;
}
.side-bar-menu-ul{
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 0px;
  border-bottom: 0.3px solid #80808052;
}

.side-bar-menu-ul>li{
  color: black;
  font-size: 20px;
  font-weight: 700;

}
.btn-side-nav1{
  border: 1px solid black;
  padding: 6px 20px;
  color: white;
  background: black;
  border-radius: 17px;
  transition: all 0.5s;

}
.btn-side-nav2{
  border: 0.3px solid gray;
  padding: 6px 20px;
  color: black;
  background: white;
  border-radius: 17px;
  transition: all 0.5s;

}

.side-nav-btn-div{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 60px;

}
.side-nav-btn-div>button:active{
  transform: scale(0.8);
  transition: all 0.5s;


}
.none-d-sidenav{

  }
.none-f-sidenav{



}


.cart-count-nav{
  display: flex;
  align-items: center;
}
.user-name-nav{
  font-size: 16px;
  color: white;
  font-weight: 100;
  cursor: pointer;
  
}
.user-name-nav-black{
  font-size: 16px;
  color: black;
  font-weight: 100;
  cursor: pointer;
}
.right-h-user{
  display: flex;
  gap: 10px;
  align-items: center ;
}
.right-h-user1{
  display: flex;


  align-items: center;
  justify-content: space-between;
}
.btn-log-out-nav{

  /* border: 0.3px solid gray; */
  padding: 6px 20px;
  color: black;
  background: white;
  border-radius: 17px;

}