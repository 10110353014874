.card-prod-1main {
  display: flex;
  gap: 55px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 0px;
}
.card-prod-2 {
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.card-h-img1 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.card-pro-content {
}
.card-img-1-p {
  width: 270px;
  height: 346px;
  object-fit: cover;
  transition: all 0.5s;

}
.pro-offer-c {
  font-size: 14px;
  font-weight: 400;
  color: green;
}
.pro-price-h1 {
  font-size: 15px;
  font-weight: 400;
}
.pro-dec1 {
  font-size: 12px;
}
.pro-name-h1 {
  font-size: 18px;
}
.card-pro-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}
.card-flex-1 {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.q-v-h1 {
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
}
.p-a-card-f {
  position: absolute;
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
  padding: 10px;
  background: black;
}
.p-a-card-f {
  visibility: hidden;
}
.card-prod-2:hover .p-a-card-f {
  visibility: visible;
}

@media only screen and (max-width: 680px) {
    .card-prod-2 {
        width: 200px;
        
    }
    .card-img-1-p {
        width: 200px;
    }
}

@media only screen and (max-width: 665px) {
    .card-prod-2 {
        width: 150px;
        
    }
    .card-img-1-p {
        width: 150px;
    }
}
@media only screen and (max-width: 350px) {

    .card-prod-2 {
        width: 110px ;
        
    }
    .card-img-1-p {
        width: 110px ;
    }
}
/* .card-img-1-p:hover{
    transform: scale(1.2);
  transition: all 0.5s;
  
} */