.input-form-check[type="text"] {
  box-sizing: border-box;
  width: 100%;
  height: calc(3em + 2px);
  margin: 0 0 1em;
  padding: 6px;
  border: 1px solid #bababa;
  border-radius: 3px;
  background: #fff;
  font-size: 16px;
  resize: none;
  outline: none;
}
.input-form-check[type="text"]:focus {
  border-color: black;
}
.input-form-check[type="text"]:focus + label[placeholder]:before {
  color: black;
}
.input-form-check[type="text"]:focus + label[placeholder]:before,
.input-form-check[type="text"]:valid + label[placeholder]:before {
  transition-duration: 0.2s;
  transform: translate(0, -1.6em) scale(0.9, 0.9);
  font-weight: bold;
}
.input-form-check[type="text"]:valid {
  border-color: black;
}
.input-form-check[type="text"]:valid + label[placeholder]:before {
  color: black;
}
.input-form-check[type="text"]:invalid + label[placeholder][alt]:before {
  content: attr(alt);
}
.input-form-check[type="text"] + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: 1.25em;
  margin-top: calc(-3em - 2px);
  margin-bottom: calc((3em - 1em) + 2px);
}
.input-form-check[type="text"] + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(0.5em + 2px);
  padding: 0 2px;
  color: #7d7d7d;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
}

.check-d-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}

.check-d-2 {
  width: 90%;
}
.check-h1 {
  font-size: 17px;
}

.log-btn-check {
  border: 1px solid;
  padding: 5px 14px;
  border-radius: 15px;
}
.log-btn-div-check {
  padding: 20px 0px;
}
.log-btn-check:hover {
  background-color: rgba(128, 128, 128, 0.164);
}
.form-check-address {
  padding: 25px 0px;
}
.form-check-div2 {
}
.left-h-check {
  /* width: 30%; */
}
.d-f-c-8 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.check-content-head-div {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.check-proimg {
  width: 100px;
}
.check-out-btnn-cart1 {
  border: 1px solid;
  padding: 10px ;
  border-radius: 5px;
  background: black;
  color: white;
  width: 100%;
}
@media only screen and (max-width:900px) {
  .check-content-head-div {
    display: flex;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
}


}
@media only screen and (max-width:500px) {


.check-d-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px 50px 0px;
}
.d-f-cart12 {

  gap: 6px !important;
}
}

.d-f-car-6{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

}

@media only screen and (max-width:600px) {
  .d-f-cart12 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6px;
    flex-direction: column;
}


}

@media only screen and (max-width:360px) {
  .d-f-car-6 {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  

}

.d-f-cart12 {
  display: flex;

  gap: 6px;

}
